import React, { ReactElement, useRef } from 'react'
import cx from 'classnames'
import './styles.css'

import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'
import { Image } from 'src/components/image'

export interface FullBleedHeroProps {
  data: {
    text: any[]
    backgroundColor?: string
    containerSize?: string
    spacing?: string
    bgImage: { asset: { _id: string; _ref?: string } }
    mobileBgImage: { asset: { _id: string; _ref?: string } }
    brightnessPercentage?: number
  }
}

export const FullBleedHero = ({ data }: FullBleedHeroProps): ReactElement => {
  const {
    text,
    backgroundColor,
    containerSize,
    spacing,
    bgImage,
    mobileBgImage,
    brightnessPercentage
  } = data
  const dom = useRef()

  const desktopImageIdentifier = bgImage?.asset?._id || bgImage?.asset?._ref
  const mobileImageIdentifier = mobileBgImage?.asset?._id || mobileBgImage?.asset?._ref
  const brightnessCss = brightnessPercentage ? `brightness(${brightnessPercentage}%)` : 'brightness(100%)'
  const rawStyleForAllImages = {objectPosition: 'top center', filter: brightnessCss}

  return (
    <div
      ref={dom}
      style={{height: 'calc(100vh - 75px)'}}
      className={cx('rel', 'is-visible', backgroundColor, spacing, {
        cw: backgroundColor === 'bcb',
        pyl: spacing === undefined,
        'cw bcb': backgroundColor === undefined,
      })}
    >
      {desktopImageIdentifier && (
        <Image
          className='bg-image show__desktop'
          imageId={desktopImageIdentifier}
          alt={''}
          rawStyleOverride={rawStyleForAllImages}
          raw={bgImage}
          lazy
        />
      )}

      {mobileImageIdentifier && (
        <Image
          className='bg-image show__mobile'
          imageId={mobileImageIdentifier}
          alt={''}
          rawStyleOverride={rawStyleForAllImages}
          raw={mobileBgImage}
          lazy
        />
      )}

      {!mobileImageIdentifier && desktopImageIdentifier && (
        <Image
          className='bg-image show__mobile'
          imageId={mobileImageIdentifier}
          alt={''}
          rawStyleOverride={rawStyleForAllImages}
          raw={bgImage}
          lazy
        />
      )}

      <div className="container--l outer mxa hero__inner f jcs ais py2 p1 al x rel">
        <div
          className={cx('x animate opacity slide-up d2 col-m-7 col-xs-24', {
            containerSize,
          })}
        >
          <BlockContent blocks={text} serializers={Serializer} />
        </div>
        
      </div>
    </div>
  )
}

export default FullBleedHero
