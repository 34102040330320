import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'

import { FeatureCard } from 'src/components/inline/featureCard'
import { ButtonBlock } from 'src/components/inline/buttonBlock'
import { Experiment } from 'src/components/global/experiment'
import { NewsletterBlock } from 'src/components/inline/newsletterBlock'
import { ColumnText } from 'src/components/inline/columnText'

import { handleSlug } from 'src/utils/utils'

import { SmallArrow } from 'src/components/svgs'

export const Serializer = {
  marks: {
    annotationLink: (props: { children: any }) => {
      return (
        <Link
          className="underline cpink"
          to={handleSlug(
            props.mark.reference._type,
            props.mark.reference.content.main.slug.current
          )}
        >
          {props.children}
        </Link>
      )
    },
    internalLink: (props: { children: any; mark: any }) => {
      if (props.mark.reference && props.mark.reference.content) {
        if (props.mark.linkType === 'text-arrow') {
          return (
            <Link
              className={cx(' f jcs aic')}
              role="button"
              aria-label={props.mark.linkType}
              to={handleSlug(
                props.mark.reference._type,
                props.mark.reference.content.main.slug.current
              )}
            >
              <span
                className={cx('', {
                  'small bold underline ls1':
                    props.mark.linkType === 'text-arrow',
                })}
              >
                {props.children}
              </span>

              {props.mark.linkType === 'text-arrow' && <SmallArrow />}
            </Link>
          )
        } else {
          return (
            <Link
              id={
                props.mark.linkType === 'one-trust-consent-modal'
                  ? 'ot-sdk-btn'
                  : undefined
              }
              className={cx(' inline-block', {
                ' ls1 button s14 my1  no-underline ':
                  props.mark.linkType === 'button',
                'small  bold underline ls1': props.mark.linkType === 'text',
              })}
              role={props.mark.linkType}
              to={handleSlug(
                props.mark.reference._type,
                props.mark.reference.content.main.slug.current
              )}
            >
              {props.children}
            </Link>
          )
        }
      } else {
        return <span>{props.children}</span>
      }
    },
    externalLink: (props: { children: any; mark: any }) => {
      return (
        <a
          id={
            props.mark.linkType === 'one-trust-consent-modal'
              ? 'ot-sdk-btn'
              : undefined
          }
          className={cx('small bold underline', {
            'f jcs aic': props.mark.linkType === ('text-arrow' || undefined),
          })}
          href={props.mark.url}
          target="_blank"
        >
          {props.children}
          {props.mark.linkType === ('text-arrow' || undefined) && (
            <SmallArrow />
          )}
        </a>
      )
    },
    highlight: (props: { children: any }) => {
      return (
        <span className="bg-primary-3 mono ss14 sm16 caps px1 py05 br5 cw">
          {props.children}
        </span>
      )
    },
  },
  types: {
    featureCard: (props: { node: {} }) => {
      return <FeatureCard data={props.node} />
    },
    newsletterBlock: (props: { node: {} }) => {
      return <NewsletterBlock data={props.node} />
    },
    divider: (props: { node: {} }) => {
      return <div className={cx('divider x', props.node.dividerType)} />
    },
    cta: (props: { node: {} }) => {
      return <span>cta</span>
    },

    columnText: (props: { none: {} }) => {
      return (
        <div className="f jcb fw ais">
          {props.node.items?.map(item => (
            <ColumnText {...item} key={item._key} />
          ))}
        </div>
      )
    },
    buttonBlock: (props: { node: {} }) => {
      return <ButtonBlock data={props.node} />
    },
    experiment: (props: { node: {} }) => {
      return <Experiment data={props.node} />
    },
    checkList: (props: { node: {
      backgroundColor: string
      checkboxSize: number
      color: string
      items: string[]
      showDivider: boolean
      textSize: number
    } }) => {
      return (
        <ul className="check__list" style={{
          lineHeight: `${props.node.checkboxSize + 16}px`
        }}>
          {props.node.items?.map((item, i) => (
            <li key={i} className={cx("check rel f", {
              showDivider: props.node.showDivider
            })} style={
              {
                fontSize: props.node.textSize,
                paddingLeft: props.node.checkboxSize + 8
              }
              }>
              <span className={`check__mark abs f jcc aic left ${props.node.backgroundColor}`} style={{
                width: props.node.checkboxSize,
                height: props.node.checkboxSize,

              }}>
                <svg width="16" height="13" viewBox="0 0 16 13" fill="none">
                  <path
                    d="M1.46094 7.06602L6.01805 11.4921L14.5379 1.03052"
                    stroke={props.node.color}
                    strokeWidth="1.56923"
                  />
                </svg>
              </span>
              {item}
            </li>
          ))}
        </ul>
      )
    },
    block: (props: {
      children: any[]
      node: {
        style?: string
      }
    }) => {
      const { style } = props.node

      switch (style) {
        case 'h1':
          return <h1 className="mbm30 mt30">{props.children}</h1>
        case 'large':
          return <p className="ss20 sm25">{props.children}</p>
        case 'small':
          return <p className="ss20 sm25">{props.children}</p>
        case 'h1-l':
          return <h1 className="h1 h1-large">{props.children}</h1>
        case 'h2':
          return <h2 className="mb20 mt20">{props.children}</h2>
        case 'h4':
          return <h4 className="mb20">{props.children}</h4>
        case 'h5':
          return <h5 className="caps mb0 w100">{props.children}</h5>
        case 'h3':
          return <h3 className="mt05 mb20 mt20">{props.children}</h3>
        default:
          // @ts-ignore
          return BlockContent.defaultSerializers.types.block(props)
      }
    },
  },
}
